/* eslint-disable no-self-assign */
import React, { ReactNode, useCallback, useEffect } from "react";
import { useRouter } from "next/router";

const debug = false;
const debugLog = debug ? console.log.bind(console) : () => {};

interface InterceptLinkProps {
  children: ReactNode;
}

export const ClientSideLinkRouting: React.FC<InterceptLinkProps> = ({
  children,
}) => {
  const router = useRouter();

  // Handle magic chrome purple text hilighting
  useEffect(() => {
    debugLog("ClientSideLinkRouting useEffect");
    const handleRouteChange = (url: string) => {
      debugLog(
        `url: ${url} && pathname: ${window.location.pathname} && hash: ${window.location.hash}`,
      );
      if (
        url.includes(":~:text=") &&
        url.includes(window.location.pathname) &&
        window.location.hash /* with no hash, setting href does a hard reload */
      ) {
        debugLog(`doing the thing`);
        window.location.href = window.location.href;
        setTimeout(() => {
          window.location.href = window.location.href;
        }, 3000);
      }
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      // component unmounted, unsubscribe from the event with the `off` method:
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  const handleLinkClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const target = event.target as HTMLAnchorElement;

      if (
        target.tagName === "A" &&
        target.href.startsWith(window.location.origin) &&
        !target.hasAttribute("download")
      ) {
        event.preventDefault();
        const href = target.getAttribute("href") || "";
        router.push(href);
      }
    },
    [router],
  );

  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  return <div onClick={handleLinkClick}>{children}</div>;
};

export default ClientSideLinkRouting;
