import DeviceConnector from "./DeviceConnector";
import DeviceConnection, { CommandResult } from "./DeviceConnection";
import {
  inputLines,
  welcomeLines,
  messageLines,
  errorLines,
  appendLines,
  HistoryLine,
} from "./HistoryLine";
import { Connector } from "./REPLDrivenInterfaces";

const debug = false;
const debugLog = debug ? console.log : () => {}; // eslint-disable-line no-console

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const welcomeMsg = `
Welcome to the Notecard In-Browser Terminal.
Start making requests below.
(For advanced info, use the 'help' command.)
********************************************`;

const DISCONNECTED = "disconnected";
const LOST = "lost";
const SERIAL = "serial";
const SIMULATOR = "simulator";

export type CommandHistory = string[];
export type ConnectionType = typeof SERIAL | typeof SIMULATOR;
export type ConnectionState =
  | typeof DISCONNECTED
  | typeof LOST
  | ConnectionType;
const isConnected = (state: ConnectionState): state is ConnectionType =>
  ![LOST, DISCONNECTED].includes(state);

export interface REPL {
  // # Public Interface
  // cleanup should be called when you're done with a REPL object.
  readonly cleanup: () => void;
  readonly commandHistoryGoBack: () => void;
  readonly commandHistoryGoForward: () => void;
  readonly commandHistoryPush: (item: string) => void;
  readonly connectionState: ConnectionState;
  readonly connectSimulator: () => void;
  readonly connectUSB: () => void;
  readonly disconnect: () => void;
  readonly error: string;
  readonly historyAsString: () => string;
  readonly historyLog: HistoryLine[];
  readonly input: string;
  readonly isConnected: () => boolean;
  readonly isSerialSupported: () => boolean;
  readonly isSimSupported: () => boolean;
  readonly sendRequest: (request: string) => void;
  readonly setInput: (input: string) => void;
  readonly statusBars: Map<string, { message: string; fraction: number }>;
  readonly clearHistory: () => void;
  // onChange should be set by a UI (e.g a react component) if it needs to be
  // told when something interesting changed (e.g. because it needs to rerender)
  onChange?: () => void;
  onConnectAttempt?: () => void;
  onConnected?: (details: { type: ConnectionType }) => void;
  onConnectFailed?: (error: string) => void;
  onConnectionChange?: (isConnected: boolean) => void;
  onDisconnectAttempt?: () => void;
  onDisconnected?: () => void;
  onDisconnectFailed?: (error: string) => void;
  onInputChange?: () => void;
  onRequestSent?: (request: string) => void;
  onUnsupportedBrowserDetected?: () => void;
  userAccountUID?: string;
}

const isBrowserRuntime = typeof navigator !== "undefined";

export class REPLImpl implements REPL {
  // REPL Public Inteface

  commandHistory: CommandHistory = [];

  // commandHistoryEdits is an overlay mask of the command history that
  // represents the edits a user has made (by navigating up/down the command
  // history and editing the commands) but which they haven't yet sent to the
  // device so which aren't part of the command history yet.
  private commandHistoryEdits: CommandHistory = [];

  commandHistoryPosition = -1;

  connectionState: ConnectionState = DISCONNECTED;

  connector: Connector;

  error = "";

  historyLog: HistoryLine[];

  input = "";

  lastConnectionType: ConnectionType | undefined;

  statusBars = new Map<string, { message: string; fraction: number }>();

  onChange?: () => void;

  onConnectAttempt?: (() => void) | undefined;

  onConnected?: ((details: { type: ConnectionType }) => void) | undefined;

  onConnectFailed?: ((error: string) => void) | undefined;

  onConnectionChange?: ((isConnected: boolean) => void) | undefined;

  onDisconnectAttempt?: (() => void) | undefined;

  onDisconnected?: (() => void) | undefined;

  onDisconnectFailed?: ((error: string) => void) | undefined;

  onInputChange?: () => void;

  onRequestSent?: ((request: string) => void) | undefined;

  onSave?: (() => void) | undefined;

  onUnsupportedBrowserDetected?: (() => void) | undefined;

  userAccountUID?: string | undefined;

  // Implementation Details

  device: DeviceConnection | undefined;

  outputLineBuffer: HistoryLine[] = [];

  constructor(connector = new DeviceConnector()) {
    this.connector = connector;
    this.historyLog = welcomeLines(welcomeMsg);
    try {
      const s = localStorage?.getItem("REPLCommandHistory");
      if (s) this.commandHistory = JSON.parse(s);
    } catch (e) {
      // ignore
    }
  }

  appendToHistory(lines: HistoryLine[]) {
    this.historyLog = appendLines(this.historyLog, lines);
    this.onChange?.();
  }

  addConnectionStatusToHistory = ({
    newState,
    oldState,
  }: {
    newState: ConnectionState;
    oldState: ConnectionState;
  }) => {
    if (isConnected(newState)) {
      this.appendToHistory(
        messageLines(
          `Connected to ${newState}${newState === SIMULATOR ? " (beta)" : ""}`
        )
      );
    } else if (newState === LOST) {
      this.appendToHistory(
        messageLines(`Trying to reestablish lost connection...`)
      );
    } else {
      this.appendToHistory(messageLines(`Disconnected from ${oldState}`));
    }
  };

  setConnectionState(newConnectionState: ConnectionState) {
    const oldState = this.connectionState;
    if (newConnectionState === oldState) return; // untested - shouldn't happen
    if (newConnectionState === DISCONNECTED && oldState === LOST) {
      debugLog("Ignoring connection state change from lost to disconnected");
      return;
    }
    debugLog(`Connection state change: ${oldState} -> ${newConnectionState}`);
    this.connectionState = newConnectionState;
    this.onChange?.();

    this.onConnectionChange?.(isConnected(newConnectionState)); // manual test REPL-10
    if (isConnected(newConnectionState))
      this.onConnected?.({ type: newConnectionState });
    else this.onDisconnected?.();

    this.addConnectionStatusToHistory({
      newState: newConnectionState,
      oldState,
    });
  }

  translateDeviceError = (e: Error) => {
    if (this.device?.isLostConnection(e)) {
      return {
        friendlyMessage:
          "Lost connection to device. Attempting to reconnect...",
        isLostConnection: true,
        shouldDisconnect: true,
        shouldThrow: false,
      };
    }
    if (this.device?.isFailureToConnect(e)) {
      return {
        friendlyMessage:
          "Could not make connection. Disconnect other terminals and try again.",
        isLostConnection: false,
        shouldDisconnect: true,
        shouldThrow: false,
      };
    }
    return {
      friendlyMessage: `Please try again: ${e}`,
      isLostConnection: false,
      shouldDisconnect: true,
      shouldThrow: true,
    };
  };

  handleDeviceError = (e: Error) => {
    const { friendlyMessage, isLostConnection, shouldDisconnect, shouldThrow } =
      this.translateDeviceError(e);
    debugLog(`Device error: ${friendlyMessage}`);
    this.appendToHistory(errorLines(friendlyMessage));
    if (shouldDisconnect) {
      this.setConnectionState(isLostConnection ? LOST : DISCONNECTED);
      this.device?.disconnect(); // manual test REPL-20
      this.error = friendlyMessage;
      this.onChange?.();
    }

    if (isLostConnection) {
      this.waitToReconnect(); // async
    }

    if (shouldThrow) {
      // Bubble unfamiliar error. This is untested because if there were any other
      // errors we knew how to easily reproduce it wouldn't be unfamiliar.
      const wrappedError = new Error(`REPL Error: ${e.toString()}`);
      wrappedError.stack = e.stack;

      // Rethrow so sentry or anyone above us can catch it.
      throw wrappedError;
    }
  };

  cleanup() {
    this.device?.disconnect(); // manual test REPL-40
  }

  setInput(input: string) {
    this.commandHistoryEdit(input);
    this.input = input;
    this.onChange?.();
    this.onInputChange?.();
  }

  async sendRequestToDevice(
    d: DeviceConnection,
    request: string,
    handleStatus: (s: { message: string; fraction: number }) => void
  ) {
    this.appendToHistory(inputLines(request));
    this.commandHistoryPush(request);
    this.setInput("");
    const result: CommandResult = await d.evaluateInput(request, handleStatus);
    this.appendToHistory(result?.history || []);
    if (result?.inputTemplate) {
      this.setInput(result.inputTemplate);
    }
  }

  setStatusBar(uuid: string, status: { message: string; fraction: number }) {
    if (status.fraction < 0) {
      setTimeout(() => {
        if (this.statusBars.delete(uuid)) {
          this.onChange?.();
        }
      }, 5_000);
    } else {
      this.statusBars.set(uuid, status);
      this.onChange?.();
    }
  }

  sendRequest(request: string) {
    if (request.trim() === "clear") {
      this.clearHistory();
      this.setInput("");
      return;
    }

    if (!this.device) {
      throw new Error(
        "Error: can't send a request without a connected device. Please connect a device and try again."
      );
    }

    const uuid = Math.random().toString();
    const handleStatus = (status: { message: string; fraction: number }) => {
      this.setStatusBar(uuid, status);
    };

    this.sendRequestToDevice(this.device, request, handleStatus);
    this.onRequestSent?.(request);
  }

  isConnected() {
    return isConnected(this.connectionState);
  }

  connectDevice() {
    if (this.device) {
      this.device.onError = this.handleDeviceError;

      this.device.onOutput = (lines: HistoryLine[]) => {
        this.appendToHistory(lines);
      };

      this.device.onStatusChange = (s) => this.setStatusBar("sync", s);

      this.device.onDisconnect = () => this.setConnectionState(DISCONNECTED);

      this.device.start();
    }
  }

  async privateDisconnect() {
    await this.device?.disconnect();
    this.setConnectionState(DISCONNECTED);
  }

  // disconnect from notecard
  async disconnect() {
    this.onDisconnectAttempt?.();
    try {
      await this.privateDisconnect();
    } catch (e: any) {
      // untested. Failing to disconnect seems very unusual.
      this.setConnectionState(DISCONNECTED);
      this.onDisconnectFailed?.(e.toString());
      this.handleDeviceError(e.toString());
    }
  }

  async waitToReconnect(msRetryInterval = 400) {
    for (;;) {
      debugLog("Reconnect attempt");
      // eslint-disable-next-line no-await-in-loop
      await sleep(msRetryInterval);
      if (!this.lastConnectionType || this.connectionState !== LOST) return;
      // eslint-disable-next-line no-await-in-loop
      await this.connect(this.lastConnectionType, { doNotPromptUser: true });
    }
  }

  // connect to notecard
  async connect(
    connectionType: ConnectionType,
    options = { doNotPromptUser: false }
  ) {
    if (this.isConnected()) await this.disconnect(); // manual test REPL-50
    this.onConnectAttempt?.(); // manual test REPL-60
    try {
      const connection = await this.connector.connect(
        connectionType === SIMULATOR
          ? { type: SIMULATOR, userID: this.userAccountUID || "" }
          : {
              type: SERIAL,
              onDisconnect: () => this.disconnect(),
              doNotPromptUser: options.doNotPromptUser,
            }

        // manual test REPL-10
      );

      if (connection) {
        this.device = connection;
        this.error = ""; // manual test REPL-70
        this.lastConnectionType = connectionType;
        this.setConnectionState(connectionType);
        this.connectDevice();
      }
    } catch (e: any) {
      if (e.toString().includes("No port selected by the user.")) {
        // user decided not to select a port. no big deal.
        // manual test REPL-80
        return;
      }
      this.onConnectFailed?.(e.toString());
      this.handleDeviceError(e);
    }
  }

  connectUSB() {
    // manual test REPL-10
    this.connect(SERIAL);
  }

  connectSimulator() {
    this.connect(SIMULATOR);
  }

  historyAsString(): string {
    return this.historyLog.map((entry) => entry.line).join("\n");
  }

  // eslint-disable-next-line class-methods-use-this
  isSerialSupported() {
    const isSerialSupported = isBrowserRuntime && "serial" in navigator; // manual test REPL-90
    if (!isSerialSupported) this.onUnsupportedBrowserDetected?.(); // manual test REPL-95Open
    return isSerialSupported;
  }

  isSimSupported() {
    return this.isSerialSupported() && !!this.userAccountUID;
  }

  commandHistorySave() {
    if (localStorage)
      localStorage.setItem(
        "REPLCommandHistory",
        JSON.stringify(this.commandHistory.slice(0, 1000))
      );
  }

  commandHistoryPush(command: string) {
    // prevent repeating duplicate commands from being saved in command history
    if (this.commandHistory[0] !== command) {
      // Backwards so that we can index from 0 when accessing
      this.commandHistory = [command, ...this.commandHistory];
      this.commandHistoryEdits = [];
      this.commandHistorySave();
    }
    this.commandHistoryPosition = -1;
  }

  commandHistoryEdit(command: string) {
    this.commandHistoryEdits[this.commandHistoryPosition] = command;
  }

  commandHistoryGoBack() {
    if (this.commandHistoryPosition < this.commandHistory.length - 1) {
      this.commandHistoryPosition += 1;
      this.setInput(
        this.commandHistoryEdits[this.commandHistoryPosition] ||
          this.commandHistory[this.commandHistoryPosition]
      );
    }
  }

  commandHistoryGoForward() {
    if (this.commandHistoryPosition > -1) {
      this.commandHistoryPosition -= 1;
    }
    this.setInput(
      this.commandHistoryEdits[this.commandHistoryPosition] ||
        this.commandHistory[this.commandHistoryPosition] ||
        ""
    );
  }

  clearHistory() {
    this.historyLog = [];
    this.onChange?.();
  }
}

export default REPL;
