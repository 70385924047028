/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import { BLUES_VENDOR_ID, MOST_NOTECARD_PRODUCT_ID } from "./SerialConnection";
import { SerialPort, SerialPortInfo } from "./WebSerialStandard";

const debug = false;
const debugLog = debug ? console.log : () => { };

// Time to wait after getting non-ok http status from restful-softcard
const msBackoff = 10_000;

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const decoder = new TextDecoder("utf-8");

// SimulatorPort translates the restful-softcard HTTP Rest API to the SerialPort
// interface required by the In-Browser Terminal.
export default class SimulatorPort implements SerialPort {
  config: { userID: string; softcardServiceURL: string };

  closed = true;

  connected = false;

  outputStreamController?: ReadableStreamDefaultController;

  private async throttle(fn: () => Promise<Response>) {
    const response = await fn();
    if (!response.ok) {
      await sleep(msBackoff);
    }
    return response;
  }

  fetchControllers = new Set<AbortController>();

  private softcardServiceFetch(path: string, body?: string) {
    const controller = new AbortController();
    this.fetchControllers.add(controller);
    const { signal } = controller;

    const response = fetch(`${this.config.softcardServiceURL}${path}`, {
      credentials: "include",
      method: "POST",
      body,
      headers: { "X-User-UID": this.config.userID },
      signal,
    }).then((r) => {
      this.fetchControllers.delete(controller);
      return r;
    });
    return response;
  }

  private rawReadByFetch() {
    const response = this.softcardServiceFetch("/v1/read");
    return response;
  }

  private async readFromSoftcard() {
    return this.throttle(() => this.rawReadByFetch());
  }

  private async readLoop() {
    if (!this.outputStreamController)
      throw new Error("readLoop: no controller");
    const encoder = new TextEncoder(); // Only does utf-8
    while (!this.closed) {
      try {
        const response = await this.readFromSoftcard();
        const txt = await response.text();
        this.outputStreamController.enqueue(encoder.encode(txt));
      } catch (error) {
        debugLog(`readLoop: caught error ${error}`);
        this.close();
        debugLog(`readLoop: closed port`);
        this.outputStreamController.error(
          new Error("The simulator connection has been lost.")
        );
      }
    }
  }

  private createReadableStream() {
    const outerThis = this;
    return new ReadableStream({
      // callback
      start(controller) {
        // capture the controller
        outerThis.outputStreamController = controller;
      },
    });
  }

  private rawWriteByFetch(toWrite: BufferSource) {
    const decoded = decoder.decode(toWrite);
    const response = this.softcardServiceFetch("/v1/write", decoded).catch(
      (e) => {
        debugLog(`rawWriteByFetch: caught error ${e}`);
        this.close();
        debugLog(`rawWriteByFetch: closed port`);
        throw e;
      }
    );
    return response;
  }

  private async writeToSoftcard(toWrite: BufferSource) {
    const response = await this.throttle(() => this.rawWriteByFetch(toWrite));
    return response;
  }

  private createWritableStream() {
    const outerThis = this;
    return new WritableStream({
      // Implement the sink
      write(chunk) {
        outerThis.writeToSoftcard(chunk);
      },
      close() {
        outerThis.closed = true;
      },
      abort(err) {
        throw new Error(`Simulator port sink error: ${err}`);
      },
    });
  }

  constructor(config: { userID: string; softcardServiceURL: string }) {
    if (config.softcardServiceURL === "") {
      throw new Error("softcardServiceURL shouldn't be empty");
    }

    this.config = config;

    this.writable = this.createWritableStream();
    this.readable = this.createReadableStream();
  }

  // SerialPort Interface
  public readable: ReadableStream;

  public writable: WritableStream;

  public async open() {
    this.closed = false;
    this.readLoop(); // async
    try {
      debugLog(`open: writing`);
      await this.writeToSoftcard(Buffer.from("\n"));
      debugLog(`open: wrote`);
    } catch (e: any) {
      debugLog(`open: failed to write: ${e}`);
      throw new Error(`Failed to open simulator port: ${e}`);
    }
    this.connected = true;
    return undefined;
  }

  public async close() {
    this.connected = false;
    this.closed = true;
    this.fetchControllers.forEach((controller) => controller.abort());
    return undefined;
  }

  public getInfo(): SerialPortInfo {
    return {
      usbVendorId: BLUES_VENDOR_ID,
      usbProductId: MOST_NOTECARD_PRODUCT_ID,
    };
  }
}
