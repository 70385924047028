export type OptionObjProps = Record<string, string[]>;

export const Options: OptionObjProps = {
  quickstart_path: [
    "Notecard and Notecarrier A",
    "Notecard and Notecarrier F",
    "Notecard and Notecarrier Pi",
    "Notecard and Notecarrier X",
    "Notecard Simulator",
    "Notecard and SparkFun Qwiic Cellular",
    "Notecard and SparkFun MicroMod Cellular Function Board",
  ],
  notecarrier: [
    "Notecarrier A",
    "Notecarrier AA",
    "Notecarrier AE",
    "Notecarrier AL",
    "Notecarrier AF",

    // The sensor tutorial requires an unversioned listing
    "Notecarrier F",
    "Notecarrier Pi",

    // Versioned listings for the datasheets
    "Notecarrier F v1.0",
    "Notecarrier F v1.3",
    "Notecarrier B v1.0",
    "Notecarrier B v2.1",
    "Notecarrier B v3.1",
    "Notecarrier Pi v1.0",
    "Notecarrier Pi v2.0",
    "Notecarrier R v1.0",
    "Notecarrier X v1.2",
    "Notecarrier XM v1.2",
    "Notecarrier XP v3.1",
    "Notecarrier XS v1.1",
    "Notecarrier XS v1.2",
    "SparkFun MicroMod Cellular Function Board",
    "Sparkfun Qwiic Cellular",
  ],
  notecard: [
    "NOTE-WBGLW",
    "NOTE-WBGLWT",
    "NOTE-NBGLW",
    "NOTE-NBGL-500",
    "NOTE-WBNAW",
    "NOTE-NBNAW",
    "NOTE-WBNA-500",
    "NOTE-NBNA-500",
    "NOTE-WBEXW",
    "NOTE-WBEX-500",
    "NOTE-WIFI",
    "NOTE-ESP",
    "NOTE-LORA v1.4",
    "NOTE-LORA v2.1",
    "NOTE-NBNAN",
    "NOTE-NBGLN",
    "NOTE-WBNAN",
    "NOTE-WBEXN",
    "NOTE-MBNAN",
    "NOTE-MBNAW",
    "NOTE-MBGLN",
    "NOTE-MBGLW",
  ],
  board: [
    "Arduino Nano 33 BLE Sense",
    "Artemis Thing Plus",
    "Adafruit HUZZAH32",
    "Adafruit Feather M4 Express",
    "Blues Swan",
    "Raspberry Pi",
    "SparkFun MicroMod STM32 Processor",
    "STM32 Discovery",
    "STM32 Nucleo",
  ],
  language: [
    "CircuitPython",
    "C/C++ (Arduino/Wiring)",
    "C/C++ (STM32Cube)",
    "Python",
    "JavaScript",
    "STMDuino",
  ],
  service: [
    "ThingSpeak",
    "Azure IoT Central",
    "General HTTP/HTTPS",
    "Snowflake",
    "Initial State",
    "IoT Central",
    "InfluxDB",
    "AWS IoT Analytics",
    "Ubidots",
    "Google Cloud Platform",
    "Datacake",
    "MQTT",
    "ThingWorx",
    "Blynk",
  ],
  route_schema: [
    "Arduino IoT Cloud",
    "AWS",
    "Azure",
    "Blynk",
    "Datacake",
    "HTTPS",
    "Google Cloud",
    "MQTT",
    "Proxy for Notecard Web Requests",
    "RadNote RadResponder",
    "Slack",
    "Snowflake",
    "ThingWorx",
    "Twilio",
  ],
  swan_datasheet_version: ["Swan v1.5", "Swan v1.7", "Swan v3.0"],
  cygnet_datasheet_version: ["Cygnet v1.2"],
  airnote_datasheet_version: ["Airnote v1.0", "Airnote v2.0"],
  notecard_firmware_version: [
    "8.x LTS",
    "7.x",
    "6.x LTS",
    "5.x",
    "4.x LTS",
    "3.x",
    "2.x LTS",
  ],
};

export const getCategorizedOptions = (
  key: string,
  options: string[],
): { [key: string]: string[] } => {
  switch (key) {
    case "notecard":
      return {
        "Notecard Cellular": options.filter((o) => o.endsWith("n")).sort(),
        "Notecard Cellular (Legacy)": options
          .filter((o) => o.endsWith("-500"))
          .sort(),
        "Notecard Cell+WiFi": options
          .filter((o) => o.endsWith("w") || o.endsWith("wt"))
          .sort(),
        "Notecard LoRa": options
          .filter((o) => o.startsWith("note-lora"))
          .sort(),
        "Notecard WiFi": ["note-esp", "note-wifi"],
      };
    case "notecarrier":
      return {
        "Notecarrier A": ["notecarrier-a"],
        "Notecarrier A (Legacy)": [
          "notecarrier-aa",
          "notecarrier-ae",
          "notecarrier-af",
          "notecarrier-al",
        ],
        "Notecarrier B": options
          .filter((o) => o.includes("notecarrier-b"))
          .sort(),
        "Notecarrier F": options
          .filter((o) => o.includes("notecarrier-f"))
          .sort(),
        "Notecarrier Pi": options
          .filter((o) => o.includes("notecarrier-pi"))
          .sort(),
        "Notecarrier R": options
          .filter((o) => o.includes("notecarrier-r"))
          .sort(),
        "Notecarrier X": options
          .filter((o) => o.includes("notecarrier-x-"))
          .sort(),
        "Notecarrier XM": options
          .filter((o) => o.includes("notecarrier-xm"))
          .sort(),
        "Notecarrier XP": options
          .filter((o) => o.includes("notecarrier-xp"))
          .sort(),
        "Notecarrier XS": options
          .filter((o) => o.includes("notecarrier-xs"))
          .sort(),
      };
    default:
      throw new Error(`Unsupported categorization option: ${key}`);
  }
};

// TODO: pass this down in [[...slugs]].tsx when it gets refactored
//       and cleanup DocConfig in config/nav.ts
export type DocMeta = {
  allPaths: string[];
  defaultVals: string[];
  description: string;
  keywords: string[];
  navTitle: string;
  props: string[];
  publicationStatus: string;
  searchOnlyDefaultSlugs?: boolean;
};
